/*!
 * Created by Dong Nguyen.
 */

@import "variables";
@import "animate.css";

body {
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    //font-size: 0.875rem;
    //font-size: 1rem;
    //font-weight: 400;
    //line-height: 1.43;
    letter-spacing: 0.01071em;
    background: #f4f7fa;
}

.w100 {
    width: 100%;
}

a {
    text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.intl-tel-input {
    margin-left: -4px;
    width: 42px;
}

label {
    font-size: 14px !important;
}

input[type="text"],
input[type="password"] {
    font-size: 16px;
}

.MuiButton-containedSizeLarge {
    font-size: 16px !important;
}

.fung {
    font-size: 13px !important;
}
